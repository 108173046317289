export default class OrderData{

    Note;
    IdCarrello;
    CodiceDestinazione;
    RichiestaFattura;
    SPED_RAGSOC;
    SPED_INDIR;
    SPED_CIVICO;
    SPED_CAP;
    SPED_CITTA;
    SPED_CODNAZI;
    SPED_CODPROV;
    SPED_NOME;
    SPED_COGNOME;
    FATT_RAGSOC;
    FATT_INDIR;
    FATT_CAP;
    FATT_CITTA;
    FATT_CODFIS;
    FATT_PIVA;
    FATT_CODNAZI;
    FATT_CODPROV;
    FATT_CODSDI;
    FATT_PEC;
    FATT_NOME;
    FATT_COGNOME;
    SpeseSpedizione;
    COD_TRANS;

    constructor(note, idCarrello,  codiceDestinazione, codiceFatturazione, Spedizione, Fatturazione, SpeseSpedizione, CodTrans){
        this.Note = note;
        this.IdCarrello = idCarrello;      
        this.CodiceDestinazione = codiceDestinazione;
        this.SpeseSpedizione = SpeseSpedizione;
        if ( Spedizione ){
            this.SPED_RAGSOC= Spedizione.SPED_RAGSOC;
            this.SPED_INDIR= Spedizione.SPED_INDIR;
            this.SPED_CAP= Spedizione.SPED_CAP;
            this.SPED_CITTA= Spedizione.SPED_CITTA;
            this.SPED_CODNAZI= 'IT';
            this.SPED_CODPROV= Spedizione.SPED_CODPROV;
            this.SPED_NOME= Spedizione.SPED_NOME;
            this.SPED_COGNOME= Spedizione.SPED_COGNOME;
        }
        if ( Fatturazione ){
            this.FATT_RAGSOC= Fatturazione.FATT_RAGSOC;
            this.FATT_INDIR= Fatturazione.FATT_INDIR;
            this.FATT_CAP= Fatturazione.FATT_CAP;
            this.FATT_CITTA= Fatturazione.FATT_CITTA;
            this.FATT_CODFIS= Fatturazione.FATT_CODFIS;
            this.FATT_PIVA= Fatturazione.FATT_PIVA;
            this.FATT_CODNAZI= 'IT';
            this.FATT_CODPROV= Fatturazione.FATT_CODPROV;
            this.FATT_CODSDI = Fatturazione.FATT_CODSDI;
            this.FATT_PEC = Fatturazione.FATT_PEC;
            this.FATT_NOME = Fatturazione.FATT_NOME;
            this.FATT_COGNOME = Fatturazione.FATT_COGNOME;
        }

        if ( codiceFatturazione == 2) {
            this.RichiestaFattura = 1;
        } else {
            this.RichiestaFattura = 0;
        }
        this.COD_TRANS = CodTrans;
    }
}3