<template>
	<header class="ColorSkin">
		<div class="Container">
			<div class="Box">
				<div class="Logo">
					<a href="/">
						<img src="images/Logo.png" srcset="images/LogoRetina.png 2x" alt="Studio Sport">
					</a>
				</div>
			</div>
		</div>
	</header>
	<section class="SigninSection">
		<div class="Container">
			<div class="Box" v-if="Transazione.esito == 'OK'">
				<h1>Grazie!</h1>
				<h2>La transazione {{Transazione.codTrans}} è andata a buon fine.</h2>
				<h2>Il tuo ordine  è stato ricevuto.</h2>
				<div class="TxtEditor">
					<p>A breve riceverà una mail contenente il riepilogo del suo ordine.</p>
					<p>Siamo sempre a sua disposizione all'indirizzo <a href="mailto:kit@studio-sport.it">kit@studio-sport.it</a></p>
					<p>Le ricordiamo che l'ordine sarà evaso entro 30 giorni, salvo ritardi nelle consegne da fornitore.</p>
				</div>
				<br/>
				<div class="Button">
					<a href="#/">Torna alla home page</a>
				</div>
			</div>
			<div class="Box" v-else>
				<h1>Oops!</h1>
				<h2>Sì è verificato un errore.</h2>
				<div class="TxtEditor">
					<p></p>
				</div>
				<div class="Box">
					<div class="Content">				
						<a href="#">Torna alla home</a>
					</div>
				</div>	
			</div>
		</div>
	</section>
</template>

<script>
import OrderService from "@/services/order.service.js";
import OrderData from "@/models/OrderData.model.js";
export default {
	name: "ThankyouPayment",
    data(){
        return {
			Transazione: [],
		}
    },
	methods: {
		InserimentoOrdine : function() {
			let NoteOrdine = (this.$store.getters.getNoteOrdine ?? " ") + " - Transazione Nexi : " + JSON.stringify(this.Transazione.codTrans);
			let ds = this.$store.getters.getDatiSpedizione;

			if(ds != null && ds != ""){
				ds = JSON.parse(this.$store.getters.getDatiSpedizione);
			}
			let df = null;
			if(this.$store.getters.getFatturazione != null && this.$store.getters.getFatturazione == 2){
				df = this.$store.getters.getDatiFatturazione;
				if(df != null && df != ""){
					df = JSON.parse(this.$store.getters.getDatiFatturazione);
					if(df.FATT_COGNOME != null && df.FATT_COGNOME == ""){
						df.FATT_COGNOME = null;
					}
					if(df.FATT_NOME != null && df.FATT_NOME == ""){
						df.FATT_NOME = null;
					}
					if(df.FATT_PEC != null && df.FATT_PEC == ""){
						df.FATT_PEC = null;
					}
					if(df.FATT_CODSDI != null && df.FATT_CODSDI == ""){
						df.FATT_CODSDI = null;
					}
				}
			}
			let orderData = new OrderData(
				NoteOrdine,
				this.$store.getters.getTestata.IdCarrello,
				this.$store.getters.getK2AnagenIndir,
				this.$store.getters.getFatturazione,
				ds,
				df ,			
				this.$store.getters.getCostoSpedizione
			);
            OrderService.sendOrder(this.$store.getters.getToken, this.$store.getters.getShop, orderData).then(
                (data) => {
					this.$store.commit("removeCart");    
					this.$store.dispatch('setDatiSpedizione', JSON.stringify({}));                
                }
            )
		}
	},

	mounted: function(){
		this.Transazione = this.$route.query;
		/*if(this.Transazione != null){
			OrderService.SendTrans(this.$store.getters.getToken, this.$store.getters.getShop, this.Transazione);
			/*if( this.$route.query.esito == 'OK'){
				this.InserimentoOrdine();
			}
		}*/
	}
}
</script>
